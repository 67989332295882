<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    xml:space="preserve"
    width="24"
    height="24"
    fill="currentColor"
  >
    <path
      d="M17.1,24c-0.4,0-0.9-0.2-1.2-0.5L5.6,13.2c-0.7-0.7-0.7-1.8,0-2.4L15.9,0.5c0.7-0.7,1.8-0.7,2.4,0c0.7,0.7,0.7,1.8,0,2.4L9.3,12l9.1,9.1c0.7,0.7,0.7,1.8,0,2.4C18,23.8,17.6,24,17.1,24z"
    />
  </svg>
</template>
